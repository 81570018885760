


























































































































































































































































import _ from "lodash";
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import CopyButton from "@/components/CopyButton.vue"
import Stack from "@/components/Stack.vue"
import * as firebase from 'firebase'
import * as E from '@/models/el';
import * as Crypto from 'crypto';

const firestore = firebase.default.firestore;
type Command = "pa" | "pb" | "sa" | "sb" | "ss" | "ra" | "rb" | "rr" | "rra" | "rrb" | "rrr";
const inverse_command: {
  [K in Command]: Command;
} = {
  pa: "pb",
  pb: "pa",
  sa: "sa",
  sb: "sb",
  ss: "ss",
  ra: "rra",
  rra: "ra",
  rb: "rrb",
  rrb: "rb",
  rr: "rrr",
  rrr: "rr",
};
type CommandLog = {
  /**
   * 試みたコマンド
   */
  attempted: Command;
  /**
   * 効果を発揮したコマンド
   */
  effected: Command[];
}


export default defineComponent({
  components: {
    CopyButton,
    Stack,
  },

  props: {
    id: {
      type: String,
    },
    ops: {
      type: String,
    },
  },

  setup(prop: {
    id: string;
    ops: string;
  }, context: SetupContext) {
    const data: {
      initial_a: E.Elm[];
      sa: E.Elm[];
      sb: E.Elm[];
      commands: CommandLog[];
      offset: number;
      string_expression: string;
      i_gauge: number;

      temp_initial: E.Elm[];
      temp_generate_end: number;
    } = reactive({
      initial_a: [],
      sa: [],
      sb: [],
      commands: [],
      offset: 0,
      string_expression: "",
      i_gauge: 0,

      temp_initial: [],
      temp_generate_end: 6,
    });

    const state: {
      show_text: any;
      show_gen: any;
      text_mode: "read" | "write";
      parse_error: string;
      show_number: boolean;
      show_order: boolean;
    } = reactive({
      show_text: null,
      show_gen: null,
      text_mode: "read",
      parse_error: "",
      show_number: true,
      show_order: false,
    });

    const command = {
      pa: (sur?: boolean) => {
        const affected = data.sb.length > 0;
        if (affected) {
          data.sa.unshift(data.sb.shift()!);
        }
        if (!sur) {
          data.commands.push({
            attempted: "pa",
            effected: affected ? ["pa"] : [],
          });
        }
        return affected;
      },
      pb: (sur?: boolean) => {
        const affected = data.sa.length > 0;
        if (affected) {
          data.sb.unshift(data.sa.shift()!);
        }
        if (!sur) {
          data.commands.push({
            attempted: "pb",
            effected: affected ? ["pb"] : [],
          });
        }
        return affected;
      },
      sa: (sur?: boolean) => {
        const affected = data.sa.length >= 2;
        if (affected) {
          const v = data.sa.shift()!;
          data.sa.splice(1, 0, v);
        }
        if (!sur) {
          data.commands.push({
            attempted: "sa",
            effected: affected ? ["sa"] : [],
          });
        }
        return affected;
      },
      sb: (sur?: boolean) => {
        const affected = data.sb.length >= 2;
        if (affected) {
          const v = data.sb.shift()!;
          data.sb.splice(1, 0, v);
        }
        if (!sur) {
          data.commands.push({
            attempted: "sb",
            effected: affected ? ["sb"] : [],
          });
        }
        return affected;
      },
      ss: (sur?: boolean) => {
        const aa = command.sa(true)
        const ab = command.sb(true)
        if (!sur) {
          data.commands.push({
            attempted: "ss",
            effected: _.compact([
              aa ? "sa" : null,
              ab ? "sb" : null,
            ]),
          });
        }
        return aa || ab;
      },
      ra: (sur?: boolean) => {
        const affected = data.sa.length >= 2;
        if (affected) {
          data.sa.push(data.sa.shift()!);
        }
        if (!sur) {
          data.commands.push({
            attempted: "ra",
            effected: affected ? ["ra"] : [],
          });
        }
        return affected;
      },
      rra: (sur?: boolean) => {
        const affected = data.sa.length >= 2;
        if (affected) {
          data.sa.unshift(data.sa.pop()!);
        }
        if (!sur) {
          data.commands.push({
            attempted: "rra",
            effected: affected ? ["rra"] : [],
          });
        }
        return affected;
      },
      rb: (sur?: boolean) => {
        const affected = data.sb.length >= 2;
        if (affected) {
          data.sb.push(data.sb.shift()!);
        }
        if (!sur) {
          data.commands.push({
            attempted: "rb",
            effected: affected ? ["rb"] : [],
          });
        }
        return affected;
      },
      rrb: (sur?: boolean) => {
        const affected = data.sb.length >= 2;
        if (affected) {
          data.sb.unshift(data.sb.pop()!);
        }
        if (!sur) {
          data.commands.push({
            attempted: "rrb",
            effected: affected ? ["rrb"] : [],
          });
        }
        return affected;
      },
      rr: (sur?: boolean) => {
        const aa = command.ra(true)
        const ab = command.rb(true)
        if (!sur) {
          data.commands.push({
            attempted: "rr",
            effected: _.compact([
              aa ? "ra" : null,
              ab ? "rb" : null,
            ]),
          });
        }
        return aa || ab;
      },
      rrr: (sur?: boolean) => {
        const aa = command.rra(true)
        const ab = command.rrb(true)
        if (!sur) {
          data.commands.push({
            attempted: "rrr",
            effected: _.compact([
              aa ? "rra" : null,
              ab ? "rrb" : null,
            ]),
          });
        }
        return aa || ab;
      },

      exec: (com: Command) => {
        if (comp.redo_able) {
          data.commands.splice(data.offset, data.commands.length - data.offset)
        }
        command[com](false)
        data.offset = data.commands.length;
        data.i_gauge = data.offset
      },

      /**
       * 初期状態に戻す(時間を0手目に戻し、命令列をクリア)
       */
      reset: () => {
        data.sa.splice(0, data.sa.length);
        data.sa.push(...data.initial_a);
        data.sb.splice(0, data.sb.length);
        data.i_gauge = 0;
        data.commands.splice(0, data.commands.length);
      },

      /**
       * 時間を1手戻す
       */
      undo: () => {
        if (!comp.undo_able.value || data.offset < 1) { return }
        data.offset -= 1;
        const com = data.commands[data.offset];
        com.effected.forEach((c) => {
          command[inverse_command[c]](true)
        })
        data.i_gauge = data.offset
      },

      /**
       * 時間を1手進める
       */
      redo: () => {
        if (!comp.redo_able.value) { return }
        const com = data.commands[data.offset];
        com.effected.forEach((c) => {
          command[c](true)
        })
        data.offset += 1;
        data.i_gauge = data.offset
      },

      /**
       * 時間を「i番目の命令が実行された直後」に変更する
       */
      jump_to: (i: number) => {
        const j = data.offset;
        if (i < j) {
          _.range(j - i).forEach(() => command.undo())
        } else if (i > j) {
          _.range(i - j).forEach(() => command.redo())
        }
        data.i_gauge = data.offset
      },
    };

    const methods = {
      str_initial: () => data.initial_a.map(s => s.n).join(" "),
      str_operations: () => data.commands.slice(0, data.offset).map(c => c.attempted),
      stringify: () => [methods.str_initial(), ...methods.str_operations()].join("\n"),
      unparse: () => {
        data.string_expression = methods.stringify()
      },
      show_text_dialog: () => {
        methods.unparse();
        state.show_text = true
      },

      switch_edit: () => {
        if (state.text_mode === "read") {
          state.parse_error = ""
          state.text_mode = "write"
        } else {
          state.text_mode = "read"
          methods.unparse();
        }
      },

      validate_operations: (ops: string[]) => {
        const invalid_lines = _(ops).map((l, i) => {
          return !((inverse_command as any)[l as Command]) ? { l, i } : null
        }).compact().value();

        return (invalid_lines.length === 0);
      },

      validate_and_parse_text: () => {
        const lines: string[] = _.compact(data.string_expression.trim().split("\n"));
        if (lines.length === 0) {
          return "入力がありません"
        }
        const args = lines.shift()!.trim();
        const margs = args.match(/^(?:-?\d+(?:\s+|$))+$/)
        if (!margs) {
          return "初期状態が正しくありません"
        }
        const compacted_lines: string[] = _(lines).map(l => l.trim()).compact().value();
        if (!methods.validate_operations(compacted_lines)) {
          return "命令列が正しくありません"
        }
        const initial = args.split(/\s+/).map(s => parseInt(s))
        if (_.uniq(initial).length < initial.length) {
          return "初期状態に重複する要素があります"
        }
        if (initial.length < 2) {
          return "初期状態は2要素以上が必要です"
        }
        methods.parse_exec(initial, compacted_lines);
        return false;
      },

      parse_exec: (initial: number[], operations: string[]) => {
        const ns: E.Elm[] = initial.map(n => ({ n, order: 0 }));
        _(ns).sortBy(t => t.n).forEach((t, i) => t.order = i + 1)
        data.initial_a.splice(0, data.initial_a.length, ...ns);
        command.reset();
        methods.add_operatons(operations)
      },

      add_operatons(operations: string[]) {
        operations.forEach(c => {
          command[c as Command]()
        });
        data.offset = data.commands.length
        data.i_gauge = data.offset
      },

      parse: () => {
        const pe = methods.validate_and_parse_text()
        if (pe) {
          state.parse_error = pe
          return
        }
        state.parse_error = ""
        state.show_text = false
      },

      show_gen_dialog: () => {
        data.temp_initial.splice(0, data.temp_initial.length, ...data.initial_a)
        state.show_gen = true
      },
    };

    const methods_gen = {
      shuffle_initial: () => {
        const shuffled = _.shuffle(data.temp_initial);
        data.temp_initial.splice(0, shuffled.length, ...shuffled)
      },

      serial_initial: () => {
        const v = parseInt(data.temp_generate_end as any);
        if (v <= 1) { return }
        data.temp_initial.splice(0, data.temp_initial.length, ..._.range(1, 1 + v).map((n,i) => ({ n, order: i + 1 })));
      },
      
      apply_initial: () => {
        methods.parse_exec(data.temp_initial.map(s => s.n), []);
        state.show_gen = false
      },

      validate_generate_end: (val: any) => {
        let v: number;
        if (typeof val === "number") {
          v = Math.floor(val);
        } else {
          v = parseInt(val);
        }
        if (v <= 1) {
          return "number must be greater than 1";
        }
        return true
      },
    };

    const urls = {
      save_state: async (final: boolean) => {
        methods.unparse();

        const str = final ? data.string_expression : methods.str_initial();
        const str_digest = Crypto.createHmac("sha256", "maumau_umauma").update(str).digest("hex").toString();

        const docs = (await firestore().collection("state").where("str_digest", "==", str_digest).get()).docs;
        let doc_ref = firestore().collection("state").doc();
        if (docs.length === 0) {
          await doc_ref.set({
            str,
            str_digest,
            time: new Date(),
          });
        } else {
          console.warn("duplicating digest");
          doc_ref = docs[0].ref;
        }
        const router = (context as any).root._router;
        const { id, ops } = router.currentRoute.params
        const next_ops = methods.str_operations().join(",");
        if (final && id !== doc_ref.id) {
          router.push(`/${doc_ref.id}`);
        } else if (!final && (id !== doc_ref.id || ops !== next_ops)) {
          router.push(`/${doc_ref.id}/${next_ops}`)
        }
      },

      load_state: async (id: string) => {
        if (!id) { return }
        console.log(`loading: ${id}`)
        const doc = firestore().collection("state").doc(id);
        const r = await doc.get();
        console.log(`loaded:`, r.data())
        const { str } = r.data() || {};
        if (!r.exists || !str) { return }
        data.string_expression = str
        methods.parse()
      },
    };

    const comp = {
      is_sorted: computed(() => {
        if (data.sb.length > 0) { return false; }
        for (let i = 1; i < data.sa.length; ++i) {
          if (data.sa[i-1] > data.sa[i]) { return false; }
        }
        return true;
      }),

      undo_able: computed(() => {
        return data.offset > 0
      }),

      redo_able: computed(() => {
        return data.commands.length - data.offset > 0
      }),
    };

    methods.parse_exec([2, 1, 3, 6, 5, 8,], []);
    command.reset()
    if (prop.id) {
      (async () => {
        await urls.load_state(prop.id);
        if (prop.ops) {
          const ops = prop.ops.trim().split(",");
          if (methods.validate_operations(ops)) {
            methods.add_operatons(ops)
          }
        }
      })();
    }

    return {
      data,
      state,
      command,
      comp,
      ...methods,
      ...methods_gen,
      urls,
      command_icon: (c: Command) => {
        switch (c) {
          case "pa": return "arrow_upward"
          case "pb": return "arrow_downward"
          case "sa": return "swap_horiz"
          case "sb": return "swap_horiz"
          case "ss": return "swap_horiz"
          case "ra": return "arrow_back"
          case "rb": return "arrow_back"
          case "rr": return "arrow_back"
          case "rra": return "arrow_forward"
          case "rrb": return "arrow_forward"
          case "rrr": return "arrow_forward"
        }
      }
    };
  }
});
