import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify'
// Initialize Firebase
import * as firebase from 'firebase'
const config = {
  apiKey: "AIzaSyA0vhod-5V6BA5Wfy6VawFeU3Gg12S7RaM",
  authDomain: "styx-29b06.firebaseapp.com",
  projectId: "styx-29b06",
  storageBucket: "styx-29b06.appspot.com",
  messagingSenderId: "1023804392713",
  appId: "1:1023804392713:web:bcfc504cf548a8d5547fd8",
  measurementId: "G-VPF9BJKB3F"
};
firebase.default.initializeApp(config);

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
