
















































import _ from "lodash";
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import * as E from '@/models/el';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    numbers: {
      type: Array,
      required: true,
    },
    total_count: {
      type: Number,
      required: true,
    },
    is_main: {
      type: Boolean,
    },
    show_number: {
      type: Boolean,
    },
    show_order: {
      type: Boolean,
    }
  },

  setup(prop: {
    name: string;
    numbers: E.Elm[];
    total_count: number;
    is_main: boolean;
    show_number: boolean;
    show_ineq: boolean;
  }, context: SetupContext) {
    return {
      is_sorted: computed(() => {
        if (!prop.is_main) { return false }
        if (prop.numbers.length < prop.total_count) { return false; }
        for (let i = 1; i < prop.numbers.length; ++i) {
          if (prop.numbers[i-1].order > prop.numbers[i].order) { return false; }
        }
        return true;
      }),
    }
  },

})
