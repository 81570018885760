









import _ from "lodash";
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },

  setup(prop: {
    text: string;
  }, context: SetupContext) {
    return {
      copyContent: () => {
        if (!navigator.clipboard) { return }
        navigator.clipboard.writeText(prop.text)
      },
    }
  },

})
